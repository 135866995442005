import styled from '@emotion/styled';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useGoogleImport } from '../../../../google-classroom';
import { useOrganization } from '../../../hooks/use-organization';
import { useFlag } from '@innovamat/flags';

const Container = styled.div`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;

const Description = styled(Typography.Body1)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type EmptyStateProps = {
  handleAddStudent: () => void;
  isClassroomImportedFromGoogle: boolean;
};

export function ClassroomStudentsEmptyState({
  handleAddStudent,
  isClassroomImportedFromGoogle,
}: EmptyStateProps): JSX.Element {
  const { t } = useTranslation();
  const { onOpenSyncroModal } = useGoogleImport();
  const { organization } = useOrganization();
  const { value: showGoogleClassroomImport } = useFlag(
    'showgoogleclassroomimport',
    false,
    {
      identifier: '',
      custom: {
        schoolId: organization?.id || '',
      },
    }
  );

  return (
    <Container>
      <EmptyClassroom />
      <div>
        <Typography.H4>Esta clase aún no tiene alumnos</Typography.H4>
        <Description>Añade alumnos usando el botón de abajo</Description>
      </div>
      {isClassroomImportedFromGoogle && !showGoogleClassroomImport ? (
        <></>
      ) : isClassroomImportedFromGoogle && showGoogleClassroomImport ? (
        <Button
          variant="secondary"
          leftIcon="GoogleClassroomIcon"
          onClick={onOpenSyncroModal}
          keepIconColor
        >
          {t('common.syncGoogleClassroom')}
        </Button>
      ) : (
        <Button rightIcon="AddUpIcon" onClick={handleAddStudent}>
          {t('students.button.addStudent')}
        </Button>
      )}
    </Container>
  );
}
