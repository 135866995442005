'use client';
import { useNavigate } from 'react-router-domv6';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import type { Roles } from '@innovamat/radiance-utils';

import { useOrganizationQuery } from '@innovamat/glow-api-client';
import { useEventLogging } from '@innovamat/event-logging';

import {
  ToolCard,
  ToolCardItem,
  Typography,
} from '@innovamat/glimmer-components';
import { APP_PATHS } from '../../../navigation';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ToolsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

type ToolOptions = ToolCardItem & {
  hiddenRoles?: Roles[];
};

type Props = {
  showFamilyPayments?: boolean;
  roles: Roles[];
  organizationId: string;
};

type ShortCutEvents = 'em' | 'training' | 'courses' | 'app';

export function Shortcuts({
  showFamilyPayments = false,
  roles,
  organizationId,
}: Props): JSX.Element {
  const { data } = useOrganizationQuery(
    { id: organizationId },
    { staleTime: Infinity }
  );
  const { t } = useTranslation();
  const { setEventData } = useEventLogging();

  const navigation = useNavigate();

  const handleSendEvent = (link: ShortCutEvents) => {
    setEventData('launcher_shortcut', { link });
  };

  const isPlatformEnabled = Boolean(data?.organization?.platformEnabled);

  const openInNewTab = (url: string): void => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const tools: ToolOptions[] = [
    {
      icon: 'TrainingIcon',
      title: t('shortcuts.training-tool.title'),
      description: t('shortcuts.training-tool.description'),
      color: 'blue',
      onClick: () => {
        handleSendEvent('training');
        openInNewTab(
          `${APP_PATHS.COURSE_SELECTOR_BASE_URL}${APP_PATHS.LEARNING_URL}`
        );
      },
    },
    {
      icon: 'CatalogIcon',
      title: t('shortcuts.catalog-tool.title'),
      description: t('shortcuts.catalog-tool.description'),
      color: 'orange',
      onClick: () => {
        handleSendEvent('courses');
        openInNewTab(
          `${APP_PATHS.COURSE_SELECTOR_BASE_URL}${APP_PATHS.COURSE_URL}`
        );
      },
    },
    {
      icon: 'AppletIcon',
      title: t('shortcuts.app-tool.title'),
      description: t('shortcuts.app-tool.description'),
      color: 'teal',
      rightIcon: 'OpenNewTabIcon',
      isDisabled: !isPlatformEnabled,
      onClick: () => {
        handleSendEvent('app');
        openInNewTab(t('shortcuts.app-tool.url'));
      },
    },
    {
      icon: 'ManipulativeEnvironmentIcon',
      title: t('shortcuts.manipulative-env-tool.title'),
      description: t('shortcuts.manipulative-env-tool.description'),
      color: 'purple',
      onClick: () => {
        handleSendEvent('em');
        openInNewTab(`${APP_PATHS.MANIPULATIVE_URL}`);
      },
    },
    {
      icon: 'OrdersIcon',
      title: t('shortcuts.family-payments.title'),
      description: t('shortcuts.family-payments.description'),
      inlineManualId: 'family_payments_shortcut',
      color: 'teal',
      onClick: () => {
        navigation(`${APP_PATHS.FAMILY_PAYMENTS_URL}`);
      },
    },
  ];

  const filterTools = (tool: ToolOptions): boolean => {
    const isFamilyPayments =
      tool.title === t('shortcuts.family-payments.title');

    if (!showFamilyPayments && isFamilyPayments) {
      return false;
    }

    if (!tool.hiddenRoles) {
      return true;
    }

    if (tool.hiddenRoles.some((role) => roles.includes(role))) {
      return false;
    }

    return true;
  };

  return (
    <Container>
      <Typography.H3>{t('shortcuts.title')}</Typography.H3>
      <ToolsContainer>
        {tools.filter(filterTools).map((tool, id) => (
          <ToolCard key={id} {...tool} />
        ))}
      </ToolsContainer>
    </Container>
  );
}
