import { HeadingSection, Loader } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useSchoolStudents } from './hooks/use-list-school-students';
import { SchoolStudentsProvider } from './providers/school-students-provider';

import { SchoolStudentsHeader } from './components/school-students-header';
import {
  SchoolStudentsTable,
  SchoolStudentsTableSkeleton,
} from './components/school-students-table';

type Props = {
  isDeletedStudentsPage?: boolean;
};

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

function SchoolStudents({ isDeletedStudentsPage = false }: Props): JSX.Element {
  return (
    <SchoolStudentsProvider isDeletedStudentsPage={isDeletedStudentsPage}>
      <SchoolStudentsContent />
    </SchoolStudentsProvider>
  );
}

function SchoolStudentsContent(): JSX.Element {
  const { t } = useTranslation();
  const { students, isLoading, isFetchingNextPage } = useSchoolStudents();

  return (
    <>
      <HeadingSection
        title={t('students.title')}
        actions={<SchoolStudentsHeader />}
      />

      {!isLoading && <SchoolStudentsTable students={students} />}

      {isLoading && !students.length && <SchoolStudentsTableSkeleton />}

      {isFetchingNextPage && (
        <Footer>
          <Loader size="md" />
        </Footer>
      )}
    </>
  );
}

export { SchoolStudents };
