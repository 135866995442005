import styled from '@emotion/styled';
import { StudentSearchInput } from './student-search-input';
import { AddStudentDropdown } from './add-student-dropdown';
import { useSchoolStudents } from '../providers/school-students-provider';

const HeaderWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-self: normal;
  align-items: center;
`;

function SchoolStudentsHeader(): JSX.Element {
  const { isDeletedStudentsPage, search, handleSearch } = useSchoolStudents();
  return (
    <HeaderWrapper>
      <StudentSearchInput search={search} handleSearch={handleSearch} />
      <AddStudentDropdown isDeletedStudentsPage={isDeletedStudentsPage} />
    </HeaderWrapper>
  );
}

export { SchoolStudentsHeader };
