import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Input,
  InputPhone,
  Select,
  Typography,
} from '@innovamat/glimmer-components';
import { useDevice } from '@innovamat/hooks';
import { getLocaleShortFormat } from '@innovamat/localization';
import { encodeBase64 } from '@innovamat/radiance-utils';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useEnvVariables } from '../../env-variables-manager';
import { useMySettings } from '../providers/my-settings-provider';

const ContentContainer = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-top: 0;
    margin-bottom: 64px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 16px;
    padding-bottom: 80px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DisconnectWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Roles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const RolesRow = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const RolesText = styled(Typography.Body1)<{ disabled?: boolean }>`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.tokens.color.alias.cm.text['text-disabled'].value
      : theme.tokens.color.alias.cm.text.text.value};
`;

const MobileBackButton = styled(IconButton)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 8px 16px;
    border-bottom: 1px solid #e8e8e8;
  }
`;

const MobileTitle = styled(Typography.H3)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

const DesktopTitle = styled(Typography.H4)`
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

const HiddenBox = styled.div`
  > div {
    display: none;
  }
`;

export function MySettingsContentDrawer(): JSX.Element | null {
  const { contentDrawerIsOpen, showDrawer } = useMySettings();
  return showDrawer ? (
    <Drawer open={contentDrawerIsOpen}>
      <MySettingsContent />
    </Drawer>
  ) : null;
}

export function MySettingsContent(): JSX.Element {
  const { t } = useTranslation();
  const {
    schoolName,
    userEmail,
    settingsValues,
    phoneCode,
    filteredLangs,
    country,
    language,
    password,
    confirmPassword,
    allPositions,
    isPageLoading,
    isValueEmpty,
    isPasswordValid,
    isPhoneValid,
    getConfirmPasswordErrorMessage,
    getChecked,
    updateField,
    handleInputPhone,
    handleChangeLang,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleChangeCheckbox,
    isLoading,
    isTabActive,
    getCurrentTitle,
    onCloseContentDrawer,
    isPositionsDisabled,
    useGoogleClassroomSync,
  } = useMySettings();

  const { isMobile } = useDevice();
  const { CORPORATE_WEBSITE } = useEnvVariables();

  const [typePassword, setTypePassword] = useState('password');
  const [typeConfirmPassword, setTypeConfirmPassword] = useState('password');

  const switchPasswordType = (type: string): string => {
    return type === 'password' ? 'text' : 'password';
  };

  const { isGoogleConnected, connections, handleDisconnect, handleConnect } =
    useGoogleClassroomSync();

  const handleToggleConnect = (): void => {
    if (isGoogleConnected) {
      handleDisconnect();
    } else {
      handleConnect();
    }
  };

  const PREFERENCE_URL = `${CORPORATE_WEBSITE}/${getLocaleShortFormat(
    language || 'es'
  )}/preferences/${encodeBase64(userEmail!)}`;

  return (
    <Grid.Col
      sm={4}
      md={isTabActive('Email') ? 6 : 5}
      lg={isTabActive('Email') ? 5 : 4}
    >
      <HiddenBox>
        <Icon icon="ViewOffIcon" />
      </HiddenBox>
      <ContentContainer>
        <TitleWrapper>
          <MobileBackButton icon={'BackIcon'} onClick={onCloseContentDrawer} />
          <MobileTitle>{getCurrentTitle()}</MobileTitle>
          <DesktopTitle>{getCurrentTitle()}</DesktopTitle>
        </TitleWrapper>

        {isTabActive('PersonalData') && (
          <Content>
            <Input
              labelText={t('Settings.Name')}
              placeholder={t('Settings.Name')}
              value={settingsValues?.firstName}
              onChange={(e) => updateField('firstName', e.target.value)}
              descriptiveText={
                !isPageLoading && isValueEmpty(settingsValues?.firstName)
                  ? t('settings.change_name.error')
                  : undefined
              }
              status={
                !isPageLoading && isValueEmpty(settingsValues?.firstName)
                  ? 'error'
                  : isPageLoading
                  ? 'disabled'
                  : undefined
              }
              data-testid="nameMySettings"
            />
            <Input
              labelText={t('Settings.Surname')}
              placeholder={t('Settings.Surname')}
              value={settingsValues?.lastName}
              onChange={(e) => updateField('lastName', e.target.value)}
              descriptiveText={
                !isPageLoading && isValueEmpty(settingsValues?.lastName)
                  ? t('settings.change_name.error')
                  : undefined
              }
              status={
                !isPageLoading && isValueEmpty(settingsValues?.lastName)
                  ? 'error'
                  : isPageLoading
                  ? 'disabled'
                  : undefined
              }
              data-testid="surnameMySettings"
            />
            <InputPhone
              label={t('Settings.Phone')}
              phone={settingsValues?.phoneNumber || phoneCode || ''}
              onChange={handleInputPhone}
              description={
                !isPhoneValid(settingsValues?.phoneNumber, phoneCode)
                  ? t(
                      'teacher.onboarding.createProfileFilled.phoneNumber.error'
                    )
                  : undefined
              }
              country={country}
              dataTestId="phoneMySettings"
              error={!isPhoneValid(settingsValues?.phoneNumber, phoneCode)}
            />
            <Input
              labelText={t('Settings.Email')}
              placeholder={t('Settings.Email')}
              value={userEmail || ''}
              status="autofilled"
              descriptiveText={
                <Trans
                  i18nKey="settings.preferences.link"
                  components={{
                    preferencesLink: (
                      <a
                        href={PREFERENCE_URL}
                        target="_blank"
                        rel="noreferrer"
                        data-testid="subscriptionButtonMySettings"
                      />
                    ),
                  }}
                />
              }
              onChange={(e) => e.preventDefault()}
            />
          </Content>
        )}

        {isTabActive('Password') && (
          <Content>
            <Input
              labelText={t('settings.change_password.title_1')}
              rightIcon={
                typePassword === 'password' ? 'ViewIcon' : 'ViewOffIcon'
              }
              type={typePassword}
              value={password}
              onChange={handleChangePassword}
              placeholder={t('setting.change_password.input_1')}
              status={
                password.length === 0
                  ? undefined
                  : !isPasswordValid(password)
                  ? 'error'
                  : undefined
              }
              descriptiveText={
                password.length === 0 || !isPasswordValid(password)
                  ? t('settings.change_password.error')
                  : t('settings.change_password.correct')
              }
              data-testid="changePasswordMySettings"
              onIconClick={() =>
                setTypePassword(switchPasswordType(typePassword))
              }
            />
            <Input
              rightIcon={
                typeConfirmPassword === 'password' ? 'ViewIcon' : 'ViewOffIcon'
              }
              labelText={t('settings.change_password.title_2')}
              type={typeConfirmPassword}
              value={confirmPassword}
              onChange={handleChangeConfirmPassword}
              placeholder={t('setting.change_password.input_2')}
              status={
                password.length === 0 || confirmPassword.length === 0
                  ? undefined
                  : getConfirmPasswordErrorMessage()
                  ? 'error'
                  : undefined
              }
              descriptiveText={
                confirmPassword.length !== 0
                  ? getConfirmPasswordErrorMessage()
                  : undefined
              }
              data-testid="repeatPasswordMySettings"
              onIconClick={() =>
                setTypeConfirmPassword(switchPasswordType(typeConfirmPassword))
              }
            />
          </Content>
        )}

        {isTabActive('Email') && (
          <Content>
            {isGoogleConnected ? (
              <DisconnectWrapper>
                <Input
                  labelText="Google"
                  type="text"
                  value={connections?.google.username || ''}
                  rightIcon="GoogleClassroomIcon"
                  status="autofilled"
                  fill
                  onChange={(e) => e.preventDefault()}
                  keepIconColor
                />
                <Button
                  variant="secondary"
                  oneLine
                  disabled={isLoading}
                  onClick={handleToggleConnect}
                  data-testid="disconnectGoogleClassroom"
                  keepIconColor
                >
                  {t('settings.accounts_connected.google.disconnect')}
                </Button>
              </DisconnectWrapper>
            ) : (
              <>
                <Wrapper>
                  <Typography.Body2>Google</Typography.Body2>
                  <Button
                    leftIcon="GoogleClassroomIcon"
                    variant="secondary"
                    disabled={isLoading}
                    onClick={handleToggleConnect}
                    data-testid="connectGoogleClassroom"
                    keepIconColor
                  >
                    {t('settings.accounts_connected.google.connect')}
                  </Button>
                </Wrapper>
              </>
            )}
          </Content>
        )}

        {isTabActive('SchoolData') && (
          <Content>
            <Input
              labelText={t('Settings.SchoolName')}
              status="autofilled"
              type="text"
              placeholder={t('Settings.SchoolName')}
              value={schoolName!}
              onChange={(e) => e.preventDefault()}
            />
            <Section>
              <Typography.Body2>
                {t('Settings.Rol en el centro')}
              </Typography.Body2>
              <Roles>
                {/* {allPositions.map(([key, _value]) => (
                  <RolesRow key={key}>
                    <RolesText>{_value as string}</RolesText>
                    <Checkbox
                      checked={getChecked(key)}
                      onChange={() => handleChangeCheckbox(key)}
                      data-testid={`${key}CheckboxMySettings`}
                    />
                  </RolesRow>
                ))} */}
                {allPositions?.map((position, index) => (
                  <RolesRow key={`${position}-${index}`}>
                    <RolesText disabled={isPositionsDisabled}>
                      {t('positions.' + position)}
                    </RolesText>
                    <Checkbox
                      checked={getChecked(position)}
                      onChange={() => handleChangeCheckbox(position)}
                      data-testid={`${position}CheckboxMySettings`}
                      disabled={isPositionsDisabled}
                    />
                  </RolesRow>
                ))}
              </Roles>
            </Section>
          </Content>
        )}
        {isTabActive('Language') && (
          <Content>
            <Select
              labelText={t('Settings.Language')}
              options={filteredLangs}
              value={
                filteredLangs.find((v) => v.value === language)?.value || null
              }
              onChange={(opt) =>
                opt ? handleChangeLang(opt.value) : undefined
              }
              menuPlacement={isMobile ? 'top' : 'auto'}
              isSearchable={!isMobile}
            />
          </Content>
        )}
      </ContentContainer>
    </Grid.Col>
  );
}
