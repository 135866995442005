import React from 'react';
import styled from '@emotion/styled';
import {
  IconButton,
  Drawer,
  Typography,
  TableTag,
} from '@innovamat/glimmer-components';
import { Trans, useTranslation } from 'react-i18next';
import { dates } from '@innovamat/radiance-utils';
import imagePractice from './img/practice.png';
import imageTraining from './img/training_zone.png';
import {
  APP_PATHS,
  normalizeImgSource,
  useCurrentNavigationValues,
  useNavigation,
} from '../../..';
import { SCHEDULE_DEFAULT_VALUES } from '../../../classrooms-management/constants';
import { DIGITAL_PRACTICE_ACCESS } from '../../../classrooms-management/hooks/use-put-classroom';

const Wrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DrawerHeader = styled.div`
  margin-bottom: 2rem;
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.tokens.color.specific.reports.tables['divider-secondary'].value};
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0.5rem 0;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.tokens.color.global.white.value};
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const Paragraph = styled(Typography.Body2)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 10rem;
  height: auto;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  float: right;
  margin-left: 1rem;
`;

const ImageLeft = styled(Image)`
  float: left;
  margin-left: 0;
  margin-right: 1rem;
`;

const TextContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ConfigSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  position: relative;
`;

const ChangeConfig = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TableTagStyled = styled.div`
  text-transform: capitalize;
`;

type KnowMoreDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const KnowMoreDrawer: React.FC<KnowMoreDrawerProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { currentClassroom } = useCurrentNavigationValues();
  const { navigateInSameNavType } = useNavigation();

  let optionPracticeAccess = '';
  if (
    currentClassroom?.digitalPracticeAccess ===
    DIGITAL_PRACTICE_ACCESS.NO_ACCESS
  ) {
    optionPracticeAccess = `<container>${t(
      'students.home-access-modal.no-access'
    )}<tag /></container>`;
  } else if (
    currentClassroom?.digitalPracticeAccess ===
    DIGITAL_PRACTICE_ACCESS.HOME_TRAINING
  ) {
    optionPracticeAccess = t('students.home-access-modal.home-training');
  } else if (
    currentClassroom?.digitalPracticeAccess ===
    DIGITAL_PRACTICE_ACCESS.HOME_ACCESS
  ) {
    optionPracticeAccess = t('students.home-access-modal.home-access');
  } else {
    optionPracticeAccess = '';
  }

  return (
    <>
      <Drawer open={isOpen} customStyles={{ width: '568px' }} roundedBorder>
        <Wrapper>
          <DrawerHeader>
            <Typography.H3>
              {t('reports.personalizedPractice.knowMore.title')}
            </Typography.H3>
            <IconButton icon="CloseIcon" onClick={onClose} />
          </DrawerHeader>

          <Section>
            <Typography.H4>
              {t('reports.personalizedPractice.knowMore.subtitle1')}
            </Typography.H4>
            <ContentContainer>
              <TextContent>
                <Paragraph>
                  <Image
                    src={normalizeImgSource(imagePractice)}
                    alt="Individual practice"
                  />
                  <Trans i18nKey="reports.personalizedPractice.knowMore.text1" />
                </Paragraph>
              </TextContent>
            </ContentContainer>
          </Section>

          <Section>
            <FlexContainer>
              <Typography.Body1>
                {t('reports.personalizedPractice.knowMore.header1')}
              </Typography.Body1>
              <TableTagStyled>
                <TableTag
                  type="catchUp"
                  text={t('contents.resource.new', 'nuevo')}
                />
              </TableTagStyled>
            </FlexContainer>
            <ContentContainer>
              <TextContent>
                <Paragraph>
                  <ImageLeft
                    src={normalizeImgSource(imageTraining)}
                    alt="Training zone"
                  />
                  <Trans i18nKey="reports.personalizedPractice.knowMore.text2" />
                </Paragraph>
              </TextContent>
            </ContentContainer>
          </Section>

          <Section>
            <Typography.Subtitle2>
              {t('reports.personalizedPractice.knowMore.header2')}
            </Typography.Subtitle2>
            <ConfigSection>
              <ChangeConfig>
                <Typography.Link2
                  onClick={() => {
                    navigateInSameNavType(APP_PATHS.CLASSROOM_STUDENTS_URL);
                  }}
                >
                  <Trans i18nKey="reports.personalizedPractice.knowMore.text4" />
                </Typography.Link2>
              </ChangeConfig>
              <div>
                <Trans
                  i18nKey="reports.personalizedPractice.knowMore.text3"
                  components={{
                    span: <Typography.Body3 />,
                    p: <Typography.Body2 />,
                    br: <Paragraph />,
                    tag: <TableTag type="accent" text={t('recommended-tag')} />,
                    container: <FlexContainer />,
                  }}
                  values={{
                    start: dates.removeSecondsFromCompleteHour(
                      currentClassroom?.scheduleStart ||
                        SCHEDULE_DEFAULT_VALUES.scheduleStart
                    ),
                    end: dates.removeSecondsFromCompleteHour(
                      currentClassroom?.scheduleEnd ||
                        SCHEDULE_DEFAULT_VALUES.scheduleEnd
                    ),
                    option: optionPracticeAccess,
                  }}
                />
              </div>
            </ConfigSection>
          </Section>
        </Wrapper>
      </Drawer>
    </>
  );
};
