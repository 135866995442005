import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import type { Theme } from '@mui/material';
import styled from '@emotion/styled';

import { TableComponent, useGlimmerTheme } from '@innovamat/glimmer-components';
import { tableStyles } from '../../../../components/common-table-components/table-styles';

const Container = styled.div`
  overflow: hidden;
  display: flex;
  align-content: center;
`;

const CellSkeleton = ({ isLastRow }: { isLastRow: boolean }): JSX.Element => {
  const theme = useGlimmerTheme();
  const backgroundColor = isLastRow
    ? theme.tokens.color.global.neutral.neutral100.value + 50
    : theme.tokens.color.global.neutral.neutral100.value;

  return (
    <Container>
      <ContentLoader
        speed={2}
        width="100%"
        height="24px"
        backgroundColor={backgroundColor}
        foregroundColor={backgroundColor}
      >
        <rect x="0" y="0" width="100%" height="24px" rx="8" />
      </ContentLoader>
    </Container>
  );
};

function generateRows(count: number): any[] {
  const result: any[] = [];

  for (let i = 0; i < count; i++) {
    result.push({
      id: i + 1,
      firstName: '',
      lastName: '',
      email: '',
      type: '',
      roles: '',
      lastLogin: '',
      reportsEnabled: '',
      isInvitation: '',
      isSchoolInvitation: '',
      token: '',
      expiresAt: '',
      classrooms: [],
    });
  }

  return result;
}

function TeachersTableSkeleton(): JSX.Element {
  const theme = useGlimmerTheme();
  const { t } = useTranslation();
  const rowsNumber = 4;
  const rows = generateRows(rowsNumber);

  const columns = [
    {
      id: 'firstName',
      label: t('common.firstName'),
      width: 150,
      minWidth: '150px',
      render: (_: any, row: any) => {
        return <CellSkeleton isLastRow={row?.id === rowsNumber} />;
      },
    },
    {
      id: 'lastName',
      label: t('common.lastName'),
      width: 150,
      minWidth: '150px',
      render: (_: any, row: any) => {
        return <CellSkeleton isLastRow={row?.id === rowsNumber} />;
      },
    },
    {
      id: 'email',
      label: t('common.email'),
      width: 300,
      minWidth: '300px',
      render: (_: any, row: any) => {
        return <CellSkeleton isLastRow={row?.id === rowsNumber} />;
      },
    },
    {
      id: 'reportsEnabled',
      label: t('teachers.receiveReports'),
      width: 100,
      minWidth: '100px',
      render: (_: any, row: any) => {
        return <CellSkeleton isLastRow={row?.id === rowsNumber} />;
      },
    },

    {
      id: 'lastLogin',
      label: t('common.status'),
      width: 150,
      minWidth: '150px',
      render: (_: any, row: any) => {
        return <CellSkeleton isLastRow={row?.id === rowsNumber} />;
      },
    },
    {
      id: 'actions',
      width: 70,
      minWidth: '70px',
      render: (_: any, row: any) => {
        return <CellSkeleton isLastRow={row?.id === rowsNumber} />;
      },
    },
  ];

  return (
    <TableComponent
      columns={columns as any}
      id="classroom-teachers-table"
      key="teachers-table-skeleton"
      rows={rows}
      sx={tableStyles(theme)}
    />
  );
}

export { TeachersTableSkeleton };
