import styled from '@emotion/styled';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useOrganizationQuery } from '@innovamat/glow-api-client';

import type { ButtonProps } from '@innovamat/glimmer-components';
import {
  AnnouncementPanel,
  Button,
  FileUploader,
  ImportCSVAnimation,
  theme,
  TitleHeader,
  Typography,
} from '@innovamat/glimmer-components';

import { useImportCSVProcess } from '../hooks/use-import-csv-process';
import { useImportCSV } from '../providers/use-import-csv-provider';
import { STEPS, type ImportType } from '../types';
import { hasToValidateStudents } from '../utils';

import { StudentsValidation } from '../components';
import { ConfirmSkipValidationModal } from '../components/confirm-skip-validation-modal';
import { ImportErrorPage } from '../components/import-error-page';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;
`;

const Description = styled.div`
  margin-top: 8px;
  a {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SkipStepFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
`;

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 180px;
  padding-top: 56px;
  gap: 32px;
`;

type Props = {
  type: ImportType;
};

const SkipValidationButton = ({
  onClick,
  variant = 'accent',
}: {
  onClick: () => void;
  variant?: ButtonProps['variant'];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button variant={variant} onClick={onClick}>
      {t('school.import_csv.skipValidationButton')}
    </Button>
  );
};

function ImportCSV({ type }: Props): JSX.Element {
  const [showConfirmSkipModal, setShowConfirmSkipModal] = useState(false);

  const { organizationId, goToImportsPage, onGoBack, canSkipValidationStep } =
    useImportCSV();
  const { t } = useTranslation();
  const { data: organizationQuery } = useOrganizationQuery(
    { id: organizationId },
    { staleTime: Infinity, enabled: !!organizationId }
  );
  const {
    csvFile,
    formData,
    importCSVStatus,
    UIState,
    handleResetForm,
    handleSetFile,
    handleSetStep,
    handleUploadFile,
  } = useImportCSVProcess();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!type || !formData) return;

    if (canSkipValidationStep) {
      handleSetStep(STEPS.SKIP_VALIDATION);
    } else if (hasToValidateStudents(organizationQuery?.organization, type)) {
      handleSetStep(STEPS.VALIDATION);
    } else {
      handleUploadFile(type);
    }
  };

  const goBack = (): void => {
    onGoBack();
  };

  const key = type === 'student' ? 'students' : 'teachers_classrooms';

  const renderUIState = (): JSX.Element => {
    switch (UIState) {
      case STEPS.SELECT:
        return (
          <>
            <Description>
              <Typography.Body1>
                <Trans
                  i18nKey={`school.import_csv.select.description.${key}`}
                  components={{
                    a: (
                      <Typography.Link1
                        color={theme.tokens.color.specific.element.link.value}
                        href={t('school.import_csv.select.specification_url')}
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    ),
                    csv: (
                      <Typography.Link1
                        color={theme.tokens.color.specific.element.link.value}
                        href={
                          type === 'classroom'
                            ? 'https://3dt0kb75phz7.b-cdn.net/5e6vii75llyz-class-list-example.csv'
                            : 'https://3dt0kb75phz7.b-cdn.net/5lfegu5pgs0-ejemplo-teachers.csv'
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    ),
                    csvEy: (
                      <Typography.Link1
                        color={theme.tokens.color.specific.element.link.value}
                        href="https://3dt0kb75phz7.b-cdn.net/564lq55starm-ejemplo-students-eype.csv"
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    ),
                    csvSe: (
                      <Typography.Link1
                        color={theme.tokens.color.specific.element.link.value}
                        href="https://3dt0kb75phz7.b-cdn.net/4qec4rw10f2v-ejemplo-students-se.csv"
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </Typography.Body1>
            </Description>

            <Form onSubmit={handleSubmit}>
              <FileUploader
                buttonLabel={t('school.import_csv.select.upload')}
                handleFile={handleSetFile}
                hasDragAndDrop={true}
                restrictedFileTypes=".csv"
                uploadedFileDescription={t(
                  'school.import_csv.select.choose_other_file'
                )}
              />
              <Footer>
                <Button
                  variant="accent"
                  type="submit"
                  disabled={!formData?.get('csv')}
                >
                  {t('school.import_csv.select.continue')}
                </Button>
              </Footer>
            </Form>
          </>
        );

      case STEPS.IMPORTING:
        return (
          <AnimationWrapper>
            <ImportCSVAnimation />
            <Typography.Subtitle1>
              {t('school.import_csv.importing', 'Importing...')}
            </Typography.Subtitle1>
          </AnimationWrapper>
        );

      case STEPS.UPLOADED:
        if (importCSVStatus.isError) {
          return <ImportErrorPage onGoBack={handleResetForm} />;
        }

        return (
          <>
            <AnnouncementPanel
              canClose={false}
              text={t('school.import_csv.uploaded.notification')}
            />
            <Description>
              <Typography.Body1>
                <Trans
                  i18nKey="school.import_csv.uploaded.description"
                  components={{
                    importsPageLink: (
                      <Typography.Link1
                        color={theme.tokens.color.specific.element.link.value}
                        onClick={goToImportsPage}
                        rel="noopener noreferrer"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </Typography.Body1>
            </Description>
            <Footer>
              <Button variant="accent" type="button" onClick={goToImportsPage}>
                {t('school.import_csv.cta.back_to_lists')}
              </Button>
            </Footer>
          </>
        );

      case STEPS.SKIP_VALIDATION:
        if (importCSVStatus.isError) {
          return <ImportErrorPage onGoBack={handleResetForm} />;
        }

        return (
          <>
            <Description>
              <Typography.Body1>
                {t(
                  'school.import_csv.skipValidation.description',
                  'El archivo tiene el formato correcto. A continuación revisaremos si hay  duplicidades en los alumnos. Puedes saltar este paso si vas a “machacar” adrede los alumnos para actualizarlos.'
                )}
              </Typography.Body1>
            </Description>
            <Typography.Subtitle1>
              {t(
                'school.import_csv.skipValidation.subtitle',
                'Quieres saltar este paso?'
              )}
            </Typography.Subtitle1>
            <SkipStepFooter>
              <SkipValidationButton
                onClick={() => setShowConfirmSkipModal(true)}
                variant="secondary"
              />

              <Button
                onClick={() => {
                  handleSetStep(STEPS.VALIDATION);
                }}
                type="button"
                variant="accent"
              >
                {t('common.continue')}
              </Button>
            </SkipStepFooter>
          </>
        );

      case STEPS.VALIDATION:
        return (
          <StudentsValidation
            csvFile={csvFile!}
            onGoBack={handleResetForm}
            onUploadFile={() => handleUploadFile(type)}
          />
        );
      default:
        return <></>;
    }
  };

  if (!type) {
    return <></>;
  }

  return (
    <>
      <TitleHeader
        actions={
          canSkipValidationStep && UIState === STEPS.VALIDATION ? (
            <SkipValidationButton
              onClick={() => setShowConfirmSkipModal(true)}
            />
          ) : undefined
        }
        hasMargin
        onGoBack={goBack}
        title={`${t(`school.import_csv.${type}`)} ${t(
          'school.import_csv.title'
        )}`}
      />
      <SectionWrapper>{renderUIState()}</SectionWrapper>

      {showConfirmSkipModal && (
        <ConfirmSkipValidationModal
          onClose={() => setShowConfirmSkipModal(false)}
          onConfirm={() => {
            handleUploadFile(type);
            setShowConfirmSkipModal(false);
          }}
          showModal={showConfirmSkipModal}
        />
      )}
    </>
  );
}

export { ImportCSV };
