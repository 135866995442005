import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

import {
  AnnouncementPanel,
  Button,
  ClassCard,
  ClassCardOptions,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import {
  type Classroom,
  useClassroomsQuery,
  useCourses,
} from '@innovamat/glow-api-client';
import { useEventListener } from '@innovamat/hooks';
import { MyClassesSkeleton } from './my-classes-skeleton';

import { getAvatarImage } from '../../../../utils';
import { useManageClassrooms } from '../../hooks/use-manage-classrooms';

import { useEventLogging } from '@innovamat/event-logging';
import {
  APP_PATHS,
  useGoogleClassroomSync,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import {
  AddClassroomDropdown,
  type AssignEventType,
} from '../add-classroom-dropdown';
import { AddClassroomModal } from '../add-classroom-modal';
import { ConfirmationModal } from '../confirmation-modal';
import { NoClassesEmptyState } from './no-classes-empty-state';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-domv6';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ClassesContainer = styled.div<{
  isExtendable: boolean;
}>`
  ${({ isExtendable }) => (isExtendable ? 'padding-bottom: 16px;' : '')}
  border-radius: 16px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  border: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  min-height: 160px;
`;

const ClassesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ClassesWrapper = styled.div<{ noClasses: boolean; maxHeight: number }>`
  padding: ${({ noClasses }) => (noClasses ? '0' : '16px')};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '158px')};
  min-height: 126px;
  overflow: hidden;
  width: 100%;

  transition: max-height 0.3s ease-in-out;
`;

const useClassesDisplay = (classrooms: Classroom[], theme: Theme) => {
  const [isExtendable, setIsExtendable] = useState(false);
  const preprocessedClassrooms = classrooms ?? [];

  const noClasses = preprocessedClassrooms.length === 0;

  const handleIsExtendable = (width: number, theme: Theme) => {
    const mdBreakpoint = parseInt(theme.breakpoints.md);
    const smBreakpoint = parseInt(theme.breakpoints.sm);

    if (width > mdBreakpoint) {
      setIsExtendable(classrooms.length > 6);
    } else if (width > smBreakpoint && width <= mdBreakpoint) {
      setIsExtendable(classrooms.length > 4);
    } else {
      setIsExtendable(classrooms.length > 2);
    }
  };

  useEffect(() => {
    const screenWidth =
      typeof window !== 'undefined' ? window.innerWidth : 1080;
    handleIsExtendable(screenWidth, theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classrooms]);

  useEventListener('resize', () => {
    const screenWidth =
      typeof window !== 'undefined' ? window.innerWidth : 1080;
    handleIsExtendable(screenWidth, theme);
  });

  return {
    noClasses,
    isExtendable,
  };
};

export function MyClasses(): JSX.Element {
  const { goToClassroom } = useNavigation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useGlimmerTheme();
  const { setEventData } = useEventLogging();

  const { user } = useUser();

  const organizationId = user?.organizationId ?? '';
  const region = user?.region ?? '';

  const {
    confirmModalData,
    editModalData,
    handleOnError,
    handleDeleteClassWithConfirmation,
    handleLeaveClassWithConfirmation,
    openEditModal,
  } = useManageClassrooms();

  const { data, isError, isSuccess } = useClassroomsQuery(
    { orgId: organizationId, mine: true },
    { staleTime: Infinity }
  );

  const classrooms = data?.classrooms?.sort(
    (a, b) => a?.courseOrder! - b?.courseOrder!
  ) as Classroom[];

  const { noClasses, isExtendable } = useClassesDisplay(
    classrooms || [],
    theme
  );

  const queryClient = useQueryClient();

  const { handleRemoveClassroomConnection, removeClassroomConnectionStatus } =
    useGoogleClassroomSync();

  const goToJoinClassroom = (): void =>
    navigate(APP_PATHS.JOIN_TO_CLASSROOM_URL);

  const goToJoinClassroomsButton = (
    <a href={APP_PATHS.JOIN_TO_CLASSROOM_FT_URL}>
      <Button>{t('common.start')}</Button>
    </a>
  );

  const { getYearUpdateCourse } = useCourses({
    organizationId: user?.organizationId!,
    regionCode: user?.region!,
  });

  useEffect(() => {
    if (removeClassroomConnectionStatus.isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['Classrooms'] });
    }
  }, [removeClassroomConnectionStatus]);

  useEffect(() => {
    if (!classrooms) return;

    const getClassroomsPerRow = (width: number) => {
      if (width > parseInt(theme.breakpoints.md)) {
        return 3;
      } else if (width > parseInt(theme.breakpoints.sm)) {
        return 2;
      } else {
        return 1;
      }
    };

    const calculateRows = (
      classroomsCount: number,
      classroomsPerRow: number
    ): number => {
      return Math.ceil(classroomsCount / classroomsPerRow);
    };

    const calculateMaxHeight = (isExpanded: boolean, rows: number) => {
      const rowHeight = 126;
      const gap = 16;
      const expandedHeight = rows * rowHeight + gap * rows;
      const collapsedHeight = 2 * rowHeight + 32;
      const margin = isExtendable ? 6 : 16; // to don't cut shadows
      return (isExpanded ? expandedHeight : collapsedHeight) + margin;
    };

    const screenWidth =
      typeof window !== 'undefined' ? window.innerWidth : 1080;
    const classroomsPerRow = getClassroomsPerRow(screenWidth);
    const rows = calculateRows(classrooms.length, classroomsPerRow);
    const newMaxHeight = calculateMaxHeight(isExpanded, rows);

    setMaxHeight(newMaxHeight);
  }, [isExpanded, classrooms, theme, isExtendable]);

  const handleClassroomDropdownEvent = (type: AssignEventType) => {
    setEventData('launcher_teacher_assign_to_class', { assign_type: type });
  };

  const createOptions = (classroom: Classroom) => {
    const options: ClassCardOptions = {
      edit: {
        text: t('common.edit'),
        onClick: () => {
          openEditModal(classroom);
        },
      },
      leave: {
        text: t('teachers.unlinkTeacher-myself'),
        onClick: () =>
          handleLeaveClassWithConfirmation({
            classroomName: classroom.name!,
            classroomId: classroom.id!,
          }),
      },
    };

    if (classroom.provider === 'google') {
      options.unsynchronize = {
        text: t('classrooms.google_classroom.desynchronize'),
        subtitle: t('classrooms.google_classroom.desynchronize.warning'),
        onClick: () => handleRemoveClassroomConnection(classroom.id),
      };
    } else {
      options.delete = {
        text: t('classrooms.deleteClassroom'),
        subtitle: t('my-classes.delete-classroom.warning'),
        onClick: () =>
          handleDeleteClassWithConfirmation({
            classroomName: classroom.name!,
            classroomId: classroom.id!,
          }),
      };
    }
    return options;
  };

  if (!isSuccess) {
    return <MyClassesSkeleton />;
  }

  return (
    <Container>
      {/* <div
        className="manager-next-inline-test"
      >
        Prova inline manual
      </div> */}
      <Header>
        <Typography.H3>{t('Mis clases')}</Typography.H3>
        <AddClassroomDropdown
          disabled={noClasses}
          onJoinClassroom={goToJoinClassroom}
          organizationId={organizationId}
          region={region}
          options={['add', 'import', 'join']}
          onError={handleOnError}
          setEvent={handleClassroomDropdownEvent}
        />
      </Header>
      <ClassesContainer isExtendable={isExtendable}>
        {isError && (
          <AnnouncementPanel
            text={t('classrooms.request.error')}
            type="error"
          />
        )}
        {noClasses ? (
          <NoClassesEmptyState
            goToJoinClassroomsButton={goToJoinClassroomsButton}
          />
        ) : (
          <ClassesWrapper maxHeight={maxHeight} noClasses={noClasses}>
            <ClassesGrid>
              {classrooms.map((classroom, index) => (
                <ClassCard
                  isImportedFromGoogle={classroom.provider === 'google'}
                  key={`${classroom.name}-${index}`}
                  name={classroom.name!}
                  course={classroom.courseName!}
                  teachers={(classroom.teachersNames || []) as string[]}
                  students={classroom.numberOfStudents || 0}
                  avatar={getAvatarImage({
                    avatar: classroom.avatar,
                    icon: classroom.icon,
                    courseOrder: classroom.courseOrder!,
                  })}
                  onClick={() => {
                    goToClassroom(classroom.id!);
                  }}
                  t={t}
                  yearUpdateNextCourse={getYearUpdateCourse(classroom)}
                  onOpenOptions={() =>
                    setEventData('launcher_open_class_options', {})
                  }
                  options={createOptions(classroom)}
                />
              ))}
            </ClassesGrid>
          </ClassesWrapper>
        )}
        {isExtendable && (
          <Button
            size="S"
            variant="tertiary"
            rightIcon={isExpanded ? 'ExpandLessIcon' : 'ExpandMoreIcon'}
            onClick={() => setIsExpanded(!isExpanded)}
            noRipple
          >
            {isExpanded ? t('my-classes.show-less') : t('my-classes.show-more')}
          </Button>
        )}
      </ClassesContainer>
      <ConfirmationModal {...confirmModalData} />
      <AddClassroomModal {...editModalData} />
    </Container>
  );
}
